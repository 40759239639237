.container {
    position: relative;
    margin-top: -13%;
    z-index: 8;
}

.wrapper {
    display: flex;
    align-items: self-start;
    justify-content: center;
    gap: .4rem;
    flex-direction: column;
    padding: .8rem 1.2rem .8rem 3%;
    overflow-x: hidden;
}

.title {
    font-family: var(--fontFamily);
    font-size: 1.2em;
    padding: 0 1%;
    font-weight: 500;
    color: white;
}


.slider {
    max-width: 100%;
    position: relative;
}

.sliderDirBtn {
    position: absolute;
    height: 100%;
    top: 0;
    border: none;
    transition: 200ms;
    background: no-repeat center url("../../public/images/right-chevron-white.png");
    background-size: contain;
    width: 2rem;
}

.sliderDirBtn:hover {
    cursor: pointer;
}

.sliderDirBtn[data-direction="left"] {
    left: -1.2rem;
    transform: scaleX(-1);
}

.sliderDirBtn[data-direction="right"] {
    right: -1.2rem;
}

.sliderItems {
    display: flex;
    flex-wrap: nowrap;
}

.sliderItem { 
    flex-shrink: 0;
    width: 32%;
    padding: 0 1%;
    border-radius: 5px;
    transition: 1s;
}

.image {
    width: 100%;
    border-radius: 5px;
    transition: transform .5s;
}

.image:hover {
    cursor: pointer;
    transform: scale(1.04);
}

@media screen and (max-width: 868px) {
    .sliderItem {
        width: 48%;
    }
}