.videoContainer {
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.videoPlayer {
    max-width: 100%;
    max-height: 100vh;
}

.extraControls,
.hide,
.slowHide {
    z-index: 100;
    position:relative;
    margin-bottom: -4%;
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding: 0 2%;
}

.hide {
    opacity: 0;
    transition: all 500ms;
}

.slowHide {
    animation: fade 1s linear 2700ms forwards alternate;
}

@keyframes fade {
    from { opacity: 1; }
    to { opacity: 0; }
}
