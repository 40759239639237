.introContainer {
    position: relative;
    max-width: 100%;
    aspect-ratio: 16/9;
    z-index: 1;
}

.introImageContainer::before {
    content: ' ';
    z-index: 100;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(to bottom, rgba(245, 246, 252, 0), var(--netflixBlack));
}

.introImage {
    position: absolute;
    width: 100%;
    height: auto;
}

.contentWrapper,
.contentWrapperCover {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 100;
    height: 100%;
    width: 100%;
    bottom: 23%;
}

.contentWrapperCover {
    bottom: 2%;
}


.muteIcon,
.muteIcon[data-fadeout="true"] {
    color: white;
    font-size: 2.8vw;
    z-index: 101;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 4%;
}

.muteIcon[data-fadeout="true"] {
    transition: bottom;
    transition-duration: 1000ms;
    transition-delay: 5000ms;
}

.muteIcon:hover {
    cursor: pointer;
}

.titleContainer {
    height: 100%;
    width: 40%;
    padding-left: 4%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    transition: transform 1.5s cubic-bezier(.165,.84,.44,1);
}

.introTitle,
.introTitle[data-fadeout="true"] {
    position: relative;
    width: 100%;
}

.introTitle[data-fadeout="true"] {
    transform-origin: left bottom;
    transform: scale(0.6) translate3d(0px, 0px, 0px);
    transition-duration: 1300ms;
    transition-delay: 5000ms;
}

.introSubtitle,
.introSubtitle[data-fadeout="true"] {
    width: 100%;
    max-height: 100%;
    padding-bottom: .5rem;
    font-size: 1.2vw;
    font-family: var(--fontFamily);
    color: white;
}

.introSubtitle[data-fadeout="true"] {
    opacity: 0;
    max-height: 0%;
    transform-origin: left bottom;
    transform: translate3d(0px, 0px, 0px) scale(0);
    transition-duration: 1300ms;
    transition-delay: 5000ms;
}

.playButton,
.playButtonDisabled,
.playButtonCover,
.playButtonDisabledCover {
    display: flex;
    gap: .3vw;
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 2vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 0 3%;
    height: 8%;
    border-radius: 5px;
}

.playButtonDisabled:hover {
    cursor: not-allowed;
    background-color: gray;
    color: rgb(39, 38, 38);
}

@media screen and (min-width: 1500px) {
   .playButtonCover, 
   .playButtonDisabledCover {
    font-size: 1.5em;
   }

   .playButton, 
   .playButtonDisabled {
    font-size: 2em;
   } 

   .muteIcon {
    font-size: 2em;
   }
}