.container {
    position: fixed;
    overflow-x: hidden;
    overflow-y: overlay;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    padding-top: 1rem;
}

.focusWrapper {
    overflow-x: hidden;
    overflow-y: overlay;
    width: 100%;
    height: 100vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    position: relative;
    display: block;
    max-width: 60rem;
    border-radius: 5px; 
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    width: 100%;
    max-width: 60rem;
    border-radius: 5px;
    background-color: var(--netflixBlack);
}

.imgContainer {
    position: relative;
}

.movieCoverOverlay {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    max-width: 60rem;
    background: linear-gradient(rgba(255, 255, 255, 0), #141414);
}

.movieCoverImg {
    display: block;
    width: 100%;
    max-width: 60rem;
    border-radius: 5px;
}

.coverHeader {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 2rem;
    padding-bottom: 4rem;
    width: 100%;
}

.coverTitle {
    font-size: 3em;
    color: var(--textColor);
    font-family: var(--fontFamily);
    max-width: 40%;
    padding-bottom: 1rem;
}

.coverButton {
    border: none;
    background-color: white;
    color: var(--netflixBlack);
    font-size: 1.2em;
    font-family: 'Nunito';
    font-weight: 500;
    padding: .2rem 2rem;
    border-radius: 5px;
}

.coverButton:hover {
    cursor: pointer;
}

.infoContainer {
    display: grid;
    grid-template-columns: 5fr 3fr;
    max-width: 60rem;
    gap: .5rem;
    padding: 1rem;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
}

.infoDetails {
    font-family: var(--fontFamily);
    color: #7f7f7f;
    padding: 1rem;
}

.infoDetails span a {
    font-family: var(--fontFamily);
    color: #0FF54F;
}

.summary {
    font-family: var(--fontFamily);
    color: var(--textColor);
    padding: 1rem;
}

.allAddedInfo {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.addedInfoContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: .5rem;
}

.addedInfoTitle {
    font-family: var(--fontFamily);
    color: #7f7f7f;
}

.addedInfo {
    font-family: var(--fontFamily);
    color: var(--textColor);
}

.close {
    position: absolute;
    right: 0;
    top:0;
    font-size: 2em;
    z-index: 200;
    color: var(--textColor);
    background-color: rgba(0, 0, 0, 0.4);
    margin: 1rem;
    border-radius: 50%; 
    padding: 0 .4rem;
}

.close:hover {
    cursor: pointer;
}

@media screen and (max-width: 868px) {
    .infoContainer {
        grid-template-columns: 1fr;
    }

    .coverTitle {
        font-size: 1em;
    }

    .coverHeader {
        padding-bottom: 2rem;
    }

    .infoDetails {
        font-size: .8em;
    }

    .addedInfoContainer {
        display: flex;
        font-size: .8em;
    }

    .summary {
        font-size: .8em;
    }
}